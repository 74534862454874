import React, { Suspense } from "react";
import {
  Container,
  Grid,
  Breadcrumb,
  Dropdown,
  Icon,
  Ref,
  Pagination,
  Loader,
  Rail,
  Sticky,
  Menu,
  Button
} from "semantic-ui-react";
import { isMobile, MobileView } from "react-device-detect";
import FilterForm from "./FilterForm";
import ListItem from "./ListItem";
import MostPopularPropertyTypes from "./MostPopularPropertyTypes";
import { getCookie, setCookie } from "../../helpers/cookie";
import { pageEnum, sortingTypeEum } from "../../Common";
import { getListingName } from "../../helpers/common";

const contextRef = React.createRef();

const List = (props) => {
  let pageNumber = getCookie("listingPageNumber");
  if (pageNumber == null || pageNumber == "") {
    pageNumber = 0;
  }

  const [filterAccordion, setFilterAccordion] = React.useState(false);
  const [activePage, setActivePage] = React.useState(pageNumber);
  const [filterFormData, setFilterFormData] = React.useState();
  const [lettingType, setLettingType] = React.useState(null);
  const [sortingType, setSortingType] = React.useState(sortingTypeEum.Default);
  const [labelSort, setLabelSort] = React.useState("Sort");
  const [mapOpen, setMapOpen] = React.useState(false);
  const [form, setForm] = React.useState({});
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  // const ListItem = React.lazy(() => import('./ListItem'))

  const filter = (filterData) => {
    setActivePage(0);
    props.getProperties(0, filterData);
    setFilterFormData(filterData);
    setCookie("listingPageNumber", 0, 1);
  };

  const renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Section link>Home</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>Sale</Breadcrumb.Section>
    </Breadcrumb>
  );

  const getPageName = () => {
    if (props.category) {
      return props.category.name;
    }
    switch (window.location.pathname) {
      case "/commercials":
        return "Commercial Properties in Malta";
      case "/residentials":
        return "Residential Properties";
      default:
        return "";
    }
  };

  const renderFilterBar = (sticky = false) => {
    if (sticky) {
      {
        /* Mobile */
      }
      return (
        <Rail position="top">
          <Sticky>
            <Menu fixed="top" style={{ marginTop: "12%" }}>
              <Grid style={styles(mapOpen).listHeader}>
                {!isMobile ? null : (
                  <Grid.Column mobile="5">
                    <a
                      class="mb-filter-button"
                      onClick={() => {
                        setFilterAccordion(!filterAccordion);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <Icon name="filter" /> Filter
                    </a>
                  </Grid.Column>
                )}
                <Grid.Column computer={8} mobile="5">
                  <Icon name="sort alphabet ascending" />
                  <Dropdown text={labelSort}>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="Newest"
                        onClick={() => {
                          setLabelSort("Sort");
                          setSortingType(sortingTypeEum.Default);
                        }}
                      />
                      <Dropdown.Item
                        text="Price Low to High"
                        onClick={() => {
                          setLabelSort("Price Low to High");
                          setSortingType(sortingTypeEum.PriceLowToHigh);
                        }}
                      />
                      <Dropdown.Item
                        text="Price High to Low"
                        onClick={() => {
                          setLabelSort("Price High to Low");
                          setSortingType(sortingTypeEum.PriceHighToLow);
                        }}
                      />
                      <Dropdown.Item
                        text="Popular"
                        onClick={() => {
                          setLabelSort("Popular");
                          setSortingType(sortingTypeEum.Popular);
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
                
              </Grid>
            </Menu>
          </Sticky>
        </Rail>
      );
    }

    {
      /* Desktop */
    }
    return (
      <Menu
        fixed={mapOpen ? "" : ""}
        style={{
          marginTop: mapOpen ? "" : "0rem",
          marginBottom: mapOpen ? "" : "-5rem",
          //marginLeft: mapOpen ? "" : "5%",
          width: mapOpen ? "" : "100%",
        }}
      >
        <Grid style={styles(mapOpen).listHeader}>
          {!isMobile ? null : (
            <Grid.Column mobile="5">
              <a
                class="mb-filter-button"
                onClick={() => {
                  setFilterAccordion(!filterAccordion);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <Icon name="filter" /> Filter
              </a>
            </Grid.Column>
          )}
          <Grid.Column computer={8} mobile="5">
            <Icon name="sort alphabet ascending" />
            <Dropdown text={labelSort}>
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Newest"
                  onClick={() => {
                    setLabelSort("Sort");
                    setSortingType(sortingTypeEum.Default);
                  }}
                />
                <Dropdown.Item
                  text="Price Low to High"
                  onClick={() => {
                    setLabelSort("Price Low to High");
                    setSortingType(sortingTypeEum.PriceLowToHigh);
                  }}
                />
                <Dropdown.Item
                  text="Price High to Low"
                  onClick={() => {
                    setLabelSort("Price High to Low");
                    setSortingType(sortingTypeEum.PriceHighToLow);
                  }}
                />
                <Dropdown.Item
                  text="Popular"
                  onClick={() => {
                    setLabelSort("Popular");
                    setSortingType(sortingTypeEum.Popular);
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid>
      </Menu>
    );
  };

  //Sale Properties
  return (
    <Container style={styles(mapOpen).container} fluid>
      <div>
        {/* {renderBreadcrumb()} */}
        <h1
          style={{
            fontSize: isMobile ? "1.5rem" : null,
            marginTop: isMobile ? 50 : 0,
          }}
        >
          {getPageName()}
        </h1>
      </div>
      <div style={{ height: isMobile ? 0 : 15 }} />
      <Ref innerRef={contextRef}>
        <Grid>
          <Grid.Row>
            {isMobile ? null : (
              <Grid.Column computer={3}>
                {/* <Sticky context={contextRef} offset={100}> */}
                <FilterForm
                  options={props.filterOptions}
                  filter={filter}
                  forms={props.form}
                  setFilterForm={props.setFilterForm}
                  setLettingType={setLettingType}
                  pageNumber={props.pageNumber}
                  sortingType={sortingType}
                  category={props.category}
                />
                {/* </Sticky> */}
              </Grid.Column>
            )}
            <Grid.Column computer={13} mobile={16}>
              {renderFilterBar(isMobile)}
              <MobileView>
                {/* {filterAccordion ? */}
                <div style={{ display: filterAccordion ? "block" : "none" }}>
                  <FilterForm
                    options={props.filterOptions}
                    filter={filter}
                    forms={props.form}
                    setFilterForm={props.setFilterForm}
                    setLettingType={setLettingType}
                    pageNumber={props.pageNumber}
                    sortingType={sortingType}
                    setFilterAccordion={() =>
                      setFilterAccordion(!filterAccordion)
                    }
                    category={props.category}
                  />
                </div>
                {/* /> : null} */}
              </MobileView>

              <Container fluid style={styles(mapOpen).items}>
                {props.propertiesLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <Loader active inline />
                  </div>
                ) : null}
                <>
                  <Grid>
                    <Grid.Row>
                      {props.properties?.map((item, index) => (
                        <Grid.Column
                          width="16"
                          computer={4}
                          mobile={16}
                          key={"property-" + item.id}
                        >
                          {/* <Suspense fallback={<div></div>}> */}
                          <ListItem {...item} />
                          {/* </Suspense> */}
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                </>

                <Pagination
                  style={{ width: "100%" }}
                  // defaultActivePage={activePage}//berk yorum satına aldı
                  totalPages={Math.ceil(props.total / 20)}
                  activePage={activePage + 1}
                  ellipsisItem={true}
                  onPageChange={(e, { activePage }) => {
                    if (activePage <= 0) {
                      return;
                    }
                    let pageIndex = activePage - 1;
                    props.getProperties(pageIndex, filterFormData);
                    setActivePage(pageIndex);
                    setCookie("listingPageNumber", pageIndex, 1);
                    window.scrollTo(0, 0);
                  }}
                />
                {props.category && props.category.description && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.category.description,
                      }}
                    ></div>
                  </div>
                )}
                {window.location.pathname === "/residentials" && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{ 
                        __html: "<h1>Property for Residential Sale in Malta</h1>" +
                                "<p>Buying Malta real estate is straightforward, but a few conditions apply—and they’re worth a close read. Let’s break down what you need to know before diving in. After this, we’ll cover some other key spots to keep in mind for a smooth property purchase.</p>" +

                                "<p>Property transactions in Malta follow specific local guidelines, so here’s what you should know before making your first investment:</p>" +
                                "<ul>" +
                                    "<li>Contracts are drafted in English—no translation needed.</li>" +
                                    "<li>Once you sign the agreement, you’ll place a 10% deposit with the Notary.</li>" +
                                    "<li>If, for some reason, the buyer can’t complete the purchase and there’s a legitimate reason, that deposit typically goes back to the seller.</li>" +
                                    "<li>These agreements generally last for three months, but both parties can choose a different time frame.</li>" +
                                    "<li>When you officially take ownership, you’ll need to cover customs duty and any other legal fees.</li>" +
                                "</ul>" +

                                "<p>Buying Malta real estate can be an exciting journey—just follow these key steps to make sure your investment is smooth sailing!</p>" +

                                "<h2>Purchasing Real Estate in Malta as a Foreigner</h2>" +
                                "<p>Malta, a trio of stunning islands in the heart of the Mediterranean, has a bit of everything. From carved stone temples to towering fortresses, these islands are bursting with historic sites, a bustling marina, and unbeatable weather—all wrapped up in a unique, vibrant culture that’s a magnet for property investors.</p>" +

                                "<p>For those interested in real estate, Malta homes for sale are a hot ticket, especially for EU citizens. With new developments popping up, it’s easy to see why Malta continues to charm investors from all corners of the globe. Property here is more than just valuable—imagine a one-bedroom waterfront apartment starting around €200,000!</p>" +

                                "<p>For investors eyeing rental income, Malta’s thriving tourism industry makes it ideal. Picture cosmopolitan neighbourhoods with historic vibes—rental options here are a treat. Malta isn’t just a dream holiday spot; it’s also a top choice for retirement, thanks to attractive government retirement and residency programs. Thousands of EU residents move to Malta each year, fueling steady growth in the real estate market.</p>" +

                                "<h2>What Should You Know Before Buying a Property in Malta?</h2>" +
                                "<p>Investing in Malta property is a smart move, with real estate here showing steady growth thanks to demand from both local and international buyers. Whether you’re looking at a charming historic home or a sleek new luxury apartment, buying property in Malta is typically straightforward. Here’s a quick overview of the buying process:</p>" +
                                "<ul>" +
                                    "<li>Find a Malta property you’d like to purchase.</li>" +
                                    "<li>Consider having an architect inspect the property’s structure on your behalf.</li>" +
                                    "<li>Agree on the price and terms with the seller. Once both parties sign, this agreement is binding.</li>" +
                                    "<li>A Notary Public will then verify the title and prepare the final sale document, a key step in protecting the rights of both buyer and seller.</li>" +
                                "</ul>" +

                                "<p>Following these steps makes securing Malta property a seamless and rewarding experience.</p>" 

                      }}
                    ></div>
                  </div>
                )}
                {window.location.pathname === "/commercials" && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{ 
                        __html: "<h1>Property for Commercial Sale in Malta</h1>"+
                                "<p>Thinking about investing in commercial property? Working with estate agents in Malta can make the process financially straightforward, but there are a few essential conditions you’ll need to meet. Let’s break these down so you’re well-prepared.</p>"+

                                "<p>Here are some key guidelines to keep in mind for a successful property transaction in Malta:</p>"+
                                "<ul>"+
                                    "<li>Contracts are written in English—easy for all parties to follow.</li>"+
                                    "<li>Once the agreement is signed, the buyer places a deposit of 10% of the purchase price with the Notary.</li>"+
                                    "<li>If the buyer can’t complete the transaction for a valid reason, this deposit is usually refunded to the seller.</li>"+
                                    "<li>Typically, the contract duration is three months, though buyers and sellers can agree on a different timeframe.</li>"+
                                    "<li>Upon taking ownership, the buyer covers customs duty and other required legal fees.</li>"+
                                "</ul>"+

                                "<p>Partnering with estate agents in Malta ensures a smooth process from start to finish, helping you navigate the unique steps of commercial property investment with ease.</p>"+

                                "<h2>Purchasing Real Estate in Malta as a Foreigner</h2>"+
                                "<p>Malta, which consists of three islands, is located in the heart of the Mediterranean. There are various historic sites scattered around the islands, including carved stone temples and massive strongholds. These historic sites, a bustling marina, great climate, and distinct culture make it an appealing destination for property investors.</p>"+

                                "<p>Malta is an excellent area to invest in real estate, particularly if you are an EU citizen. With new housing developments, it's no surprise that Malta continues to captivate property investors from around the world. In Malta, real estate is a valuable asset. A one-bedroom apartment on the water can cost up to €200,000.</p>"+

                                "<p>Rental options in cosmopolitan yet historic sections of the country will also thrill investors searching for rental income, thanks to a thriving tourism economy. Malta is a popular retirement location as well as a dream holiday destination. The government provides appealing retirement and residency schemes. Thousands of EU residents take up residence in Malta each year as a result of these schemes, contributing to the real estate industry's steady expansion.</p>"+

                                "<h2>Why Invest in Malta?</h2>"+
                                "<p>Malta’s commercial property market offers a dynamic landscape for investors, drawing interest from around the globe due to its advantageous tax regulations and strong economic growth. Positioned at the heart of the Mediterranean, Malta has long been a hub for international business, making it an attractive option for those looking to expand or establish their business operations.</p>"+

                                "<p>With sectors like fintech, iGaming, and financial services booming, investors in commercial real estate in Malta have access to a range of properties—from modern office spaces to large industrial sites. These sectors also benefit from Malta’s pro-business policies and its reputation as an English-speaking, EU-based country, which continues to boost investor confidence.</p>"+

                                "<h2>Best Spots to Set Up Shop</h2>"+
                                "<p>Another exciting factor for those seeking business properties in Malta is the country’s steady growth in tourism and hospitality, especially in popular areas like Sliema, Valletta, and St. Julian’s. These regions have seen increased demand for retail and office spaces in Malta, as more businesses look to establish a presence in high-traffic areas.</p>"+

                                "<p>Additionally, the government supports foreign investment in Maltese properties with favorable regulations, which helps ensure a stable market. For those seeking prime locations in Malta, now is an excellent time to consider commercial real estate opportunities, as the island nation continues to thrive economically and culturally.</p>"


                      }}
                    ></div>
                  </div>
                )}

                {props.category && props.category.mostPopularPropertyTypes && props.category.mostPopularPropertyTypes?.length > 0 && (
                  <MostPopularPropertyTypes mostPopularPropTypes={props.category.mostPopularPropertyTypes} />
                )}
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Ref>
    </Container>
  );
};

export default List;

const styles = (mapOpen) => ({
  container: {
    paddingTop: isMobile ? 10 : 30,
    paddingLeft: isMobile ? 0 : 20,
    paddingRight: isMobile ? 0 : 20,
  },
  listHeader: {
    margin: 0,
    padding: isMobile ? 0 : 5,
    backgroundColor: "#fff",
    borderRadius: 3,
    border: "1.5px solid #ccc",
    width: "100%",
  },
  items: {
    marginTop: isMobile ? 15 : 85,
  },
  iconButton: {
    backgroundColor: "#353a47",
    margin: 5
  },
  button: {
    backgroundColor: "#353a47",
    margin: 5
  },
  referenceNumber: {
    backgroundColor: "#fff"
  },
});
